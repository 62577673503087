body, html {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.body {
  min-height: 100vh;
  max-width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffc800;
  padding: 0 15px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


.card {
  text-align: center;
  background-color: #fff;
  text-align: center;
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,.05);
  padding: 60px 40px;
}
.card-img {
  margin: 0 auto 30px;
  max-width: 60%;
}
.card p {
  color: #5c5c5c;
}
img, svg {
  max-width: 100%;
}
.red {
  color: #e73939;
}
.card .btn {
  display: inline-block;
  margin-top: 20px;
  font-weight: 500;
  color: #212121;
  background-color: #ffc800;
  text-decoration: none;
  padding: 10px 40px;
  border-radius: 6px;
}